var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('v-modal',{attrs:{"title":_vm.$t('select_existing'),"buttons":{
			done: {
				text: _vm.$t('done'),
				color: 'accent'
			}
		}},on:{"done":function($event){return _vm.$emit('done')},"close":function($event){return _vm.$emit('cancel')}}},[_c('div',{staticClass:"search-sort"},[_c('input',{attrs:{"placeholder":_vm.$t('search'),"type":"search"},domProps:{"value":_vm.searchQuery},on:{"input":_vm.setSearchQuery}}),_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sortField),expression:"sortField"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sortField=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.fields),function(field){return _c('option',{key:field,domProps:{"value":field}},[_vm._v(" "+_vm._s(_vm.$helpers.formatField(field, _vm.collection))+" ")])}),0),_c('v-icon',{attrs:{"name":"arrow_drop_down","color":"--input-icon-color"}})],1),_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sortDirection),expression:"sortDirection"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sortDirection=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"asc"}},[_vm._v("↑")]),_c('option',{attrs:{"value":"desc"}},[_vm._v("↓")])]),_c('v-icon',{attrs:{"name":"arrow_drop_down","color":"--input-icon-color"}})],1)]),(_vm.hydrating)?_c('div',{staticClass:"spinner"},[_c('v-spinner')],1):_vm._e(),(_vm.items.length === 0 && !_vm.hydrating && !_vm.loading)?_c('div',{staticClass:"not-found"},[_vm._v(" "+_vm._s(_vm.$t('no_results'))+" ")]):_vm._e(),(!_vm.hydrating && _vm.items.length > 0)?_c('div',{staticClass:"items"},[_c('div',{staticClass:"head"},[_c('span',[(!_vm.single)?_c('v-icon',{attrs:{"name":(_vm.value || []).length == 0
								? 'check_box_outline_blank'
								: _vm.value.length == _vm.items.length
								? 'check_box'
								: 'indeterminate_check_box',"color":(_vm.value || []).length == _vm.items.length
								? '--input-background-color-active'
								: '--input-border-color'},on:{"click":function($event){return _vm.toggleAll()}}}):_vm._e()],1),(_vm.collection === 'directus_files')?_c('span',[_vm._v(_vm._s(_vm.$t('file')))]):_vm._e(),_vm._l((_vm.fields),function(field){return _c('span',{key:field},[_vm._v(" "+_vm._s(_vm.$helpers.formatField(field, _vm.collection))+" ")])})],2),_vm._l((_vm.items),function(item){return _c('label',{key:_vm.uid + '_' + item[_vm.primaryKeyField]},[_c('div',{staticClass:"input"},[_c('input',{attrs:{"type":_vm.single ? 'radio' : 'checkbox',"name":_vm.uid},domProps:{"value":item[_vm.primaryKeyField],"checked":_vm.isChecked(item[_vm.primaryKeyField])},on:{"change":function($event){return _vm.updateValue(item[_vm.primaryKeyField])}}}),(_vm.single)?_c('v-icon',{attrs:{"name":_vm.isChecked(item[_vm.primaryKeyField])
								? 'radio_button_checked'
								: 'radio_button_unchecked',"color":_vm.isChecked(item[_vm.primaryKeyField])
								? '--input-background-color-active'
								: '--input-border-color'}}):_c('v-icon',{attrs:{"name":_vm.isChecked(item[_vm.primaryKeyField])
								? 'check_box'
								: 'check_box_outline_blank',"color":_vm.isChecked(item[_vm.primaryKeyField])
								? '--input-background-color-active'
								: '--input-border-color'}})],1),(_vm.collection === 'directus_files')?_c('span',[_c('v-ext-display',{attrs:{"interface-type":"file","name":"thumbnail","collection":"directus_files","type":"JSON","datatype":"TEXT","value":item}})],1):_vm._e(),_vm._l((_vm.fieldsWithInfo),function(fieldInfo){return _c('span',{key:_vm.uid + '_' + fieldInfo.field},[_c('v-ext-display',{attrs:{"id":_vm.uid + '_' + fieldInfo.field,"interface-type":fieldInfo.interface,"name":_vm.uid + '_' + fieldInfo.field,"type":fieldInfo.type,"datatype":fieldInfo.datatype,"options":fieldInfo.options,"value":item[fieldInfo.field],"values":_vm.getItemValueById(item.id)}})],1)})],2)})],2):_vm._e(),(_vm.moreItemsAvailable && !_vm.hydrating)?_c('v-button',{staticClass:"more",attrs:{"loading":_vm.loading},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t('load_more'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }